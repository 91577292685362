.card-container {
    position: relative;
    width: 100%;
    height: 1px;
}

.chara-card {
    position: absolute;
    top: 70px;
    height: 54vw;
    max-height: 590px;
    width: 90%;
    max-width: 960px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: transform 2s, z-index 2s;
    overflow: hidden;
    background: $color_white;
    color: $color_typo_gray;
    padding: 0 2.5rem 2.5rem;
    box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.15);

    @include tablet {
        height: 75vw;
        max-height: 560px;
    }

    @include mobile {
        top: 0;
        height: 60vh;
        max-height: 540px;
        padding: 0 1.5rem 1.5rem;
    }

    @include uwide {
        top: 0;
        height: 50vh;
    }

    .left {
        position: absolute;
        width: 38%;
        max-width: 360px;
        height: calc(100% - 100px);
        top: 0;
        float: left;

        @include mobile {
            float: none;
            clear: both;
            width: 30%;
            height: 36%;
            max-height: 200px;
            box-sizing: border-box;
        }

        .image {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 20px $color_white solid;
            box-sizing: border-box;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
            overflow: hidden;

            @include tablet {
                border: 12px $color_white solid;
            }

            @include mobile {
                border: none;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                -webkit-transform: translateY(-50%) translateX(-50%);
                height: 90%;
                width: auto;
            }

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                background: linear-gradient(57deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 100%);
            }
        }

        .color-label {
            position: relative;
            width: 100%;
            height: 20px;
            margin-bottom: 24px;
            
            &:before {
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                top: 0;
                right: 60px;
                background: $color_white;
            }
        
            &:after {
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                top: 0;
                right: 32px;
                background: $color_white;
            }
        }
    }
    .right {
        margin-left: calc(38% + 40px);

        @include mobile {
            margin-left: calc(29% + 25px);
        }
    
        .card-title {
            font-size: 2.6rem;
            font-weight: 700;
            padding: 1rem 0 .2rem;
            border-bottom: 8px $color_typo_gray solid;

            @include tablet {
                font-size: 2.2rem;
                border-bottom: 6px $color_typo_gray solid;
            }

            @include mobile {
                font-size: 1rem;
                border-bottom: 2px $color_typo_gray solid;
            }
        }

        .overview {
            clear: both;
            width: 100%;
            overflow: hidden;

            .overview-item {
                display: inline-block;
                margin: .3rem;
                padding-bottom: .5rem;
                border-bottom: 1px $color_typo_gray solid;
                line-height: 25px;

                @include mobile {
                    line-height: 1;
                    margin: .2rem;
                }
            }
            .label {
                display: inline-block;
                font-size: 1.2rem;
                margin-right: .5rem;

                @include tablet {
                    font-size: 1rem;
                }

                @include mobile {
                    font-size: .8rem;
                }
            }
            .field {
                display: inline-block;
                .furigana {
                    display: block;
                    font-size: 1.3rem;

                    @include mobile {
                        font-size: .8rem;
                    }
                }

                @include tablet {
                    font-size: 1rem;
                }

                @include mobile {
                    font-size: .8rem;
                }
            }
            .value {
                display: inline-block;
                font-weight: 700;
                font-size: 1.8rem;

                @include tablet {
                    font-size: 1.6rem;
                }

                @include mobile {
                    font-size: 1.3rem;
                }
            }
            h3.name {
                font-size: 2.8rem;

                @include tablet {
                    font-size: 2.4rem;
                }

                @include mobile {
                    font-size: 1.6rem;
                }
            }
        }

        .longtext-container {
            position: relative;

            &.organization {
                padding-right: 160px;

                @include mobile {
                    padding: 0;
                }
            }

            h3.title {
                font-size: 2rem;
                font-weight: 700;
                margin: 1.8rem 0 1rem;

                @include tablet {
                    font-size: 1.8rem;
                }

                @include mobile {
                    margin: 1.2rem 0 .5rem;
                    font-size: 1.4rem;
                }
            }

            p {
                font-size: 1.5rem;

                @include tablet {
                    font-size: 1.4rem;
                    line-height: 1.8;
                }

                @include mobile {
                    font-size: 2.5vw;
                    line-height: 5vw;
                }
            }

            .organization-logo {
                position: absolute;
                top: 0;
                right: 0;
                width: 150px;

                @include mobile {
                    display: none;
                }
            }
        }
    }
    
}

.chara-list {
    display:grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    padding: 40px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: clamp(600px, 60vh, 880px);

    @include tablet {
        margin-top: clamp(540px, 65vh, 720px);
        gap: 16px;
    }

    @include mobile {
        margin-top: clamp(180px, 64vh, 680px);
        padding: 20px;
        gap: 8px;
    }

    @include uwide {
        margin-top: 570px
    }

    .item {
        position: relative;
        cursor: pointer;
        z-index: 2;
        padding-bottom: 140%;
        overflow: hidden;
        box-sizing: border-box;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
        border: 10px $color_white solid;

        @include tablet {
            border: 8px $color_white solid;
        }

        @include mobile {
            border: 3px $color_white solid;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-50%);
            width: 100%;
        }

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background: linear-gradient(57deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.9) 100%);
            z-index: 0;
        }

        &.selected:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background-size: auto auto;
            background-color: rgba(0, 0, 0, 0.3);
            background-image: repeating-linear-gradient(135deg, transparent, transparent 5px, rgba(51, 51, 51, 1) 5px, rgba(51, 51, 51, 1) 8px );
        }

        &:hover, &.selected {
            img {
                width: 110%;
            }
        }

        .hyouji {
            position: absolute;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            width: 24px;
            height: 80px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            color: $color_white;
            font-size: 2.5rem;
            z-index: 2;

            @include mobile {
                font-size: 1.2rem;
                width: 12px;
                height: 36px;
            }
        }
    }
}
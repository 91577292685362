.bgm-select {
    position: absolute;
}

.hero {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background: $color_black;

    h1.logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);
        z-index: 3;
        margin: 0;
        width: 50%;
        max-width: 640px;
    }
    .video-box {
        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    &:before {
        position: absolute;
        content: '';
        background: rgba(0,0,0,.6);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.story {
    position: relative;
    height: 100vh;
    min-height: 640px;
    background: $color_black;

    @include mobile {
        height: auto;
    }

    .story-desc {
        position: absolute;
        width: calc(100% - 240px);
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);
        text-align: center;
        box-sizing: border-box;

        @include tablet {
            width: calc(100% - 180px);
        }

        @include mobile {
            position: relative;
            width: calc(100% - 60px);
            transform: none;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 20px 40px;
        }

        p {
            margin-bottom: 2.4rem;
        }
    }

    .outline-typo {
        -webkit-text-stroke: .5px $color_accent;

        @include mobile {
            display: none;
        }
    }
}

.characters {
    position: relative;
    height: 100vh;
    max-height: 1440px;
    background: rgb(208,208,208);
    background: linear-gradient(31deg, rgba(208,208,208,1) 0%, rgba(159,159,159,1) 100%);
    overflow: hidden;
    
    @include tablet {
        max-height: 1200px;
    }

    @include mobile {
        max-height: 960px;
    }

    .heading {
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        width: 120%;
        height: 60%;
        background: rgb(0,0,0);
        background: linear-gradient(31deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.24) 100%);
        transform: rotate(12deg);
        top: -30%;
        opacity: .2;
        filter: blur(5px);
        z-index: 0;
    }

    .outline-typo {
        -webkit-text-stroke: .5px $color_white;
    }
}

.movie {
    background: $color_black;
    overflow: hidden;

    .video-box {
        position: relative;
        max-width: 880px;
        margin: 0 auto 40px;

        .youtube-container {
            width: 100%;
            padding-bottom: 56.25%;
            height: 0;
    
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}

.credit {
    text-align: center;
    padding-bottom: 60px;
    background: $color_black;

    ul.credits {
        font-size: 1.8rem;
        @include tablet {
            font-size: 1.6rem;
        }

        li {
            margin-bottom: 1.2rem;

            a {
                color: $color_white;
                text-decoration: underline;
                text-decoration-color: $color_typo_gray;
                text-decoration-style: dotted;
            }
        }
    }
}

.outline-typo {
    position: absolute;
    font-size: 5.4rem;
    -webkit-text-fill-color: transparent;
    top: 0;
    bottom: 0;
    height: 80px;
    margin: auto 0;

    @include tablet {
        opacity: .5;
    }

    &.left {
        transform: rotate(-90deg);
        left: 0;
    }
    &.right {
        transform: rotate(90deg);
        right: 0;
    }
}

.loading {
    position: fixed;
	width: 100%;
	height: 100%;
	background: $color_black;
}

.loading-effect {
	/* Example of a simple pulsating effect */
	animation: pulse 1s infinite;
}

.entrance {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font_accent;
	width: 100%;
	height: 100%;
	background: $color_black;
    font-size: 2.4rem;
    text-align: center;

    .row {
        display: block;
        line-height: 48px;
    }

    button {
        font-family: $font_accent;
        background: none;
        border: none;
        color: $color_white;
        font-size: 2.4rem;
        cursor: pointer;
        transition-duration: .5s;

        &:hover {
            transform: skew(5deg,5deg);
        }

        &.bgm-button {
            color: $color_typo_gray;
            width: 60px;
    
            &.on {
                color: $color_accent;
            }
        }
    }
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.1);
		opacity: 0.7;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes EndingTypoFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

.ending-message {

    .typo {
        width: 80%;
        max-width: 880px;
        margin: 0 auto;

        @include tablet {
            width: 100%;
        }

        img {
            margin-top: 35vh;
            margin-bottom: 42vh;
        }
    }
}

@keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fade-in-bottom {
    animation: fadeInBottom 1s ease-out forwards;
  }
.products-page {
    padding: 20px;
}
  
.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
}

.product-card {
    text-align: center;

    .product-image {
        position: relative;
        max-width: 100%;
        padding-bottom: 100%;
        height: auto;
        overflow: hidden;
    
        img {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-50%);
            margin: 0;
        }
    
        &.out-of-stock:after {
            position: absolute;
            content: '完売';
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            background-size: auto auto;
            background-color: rgba(0, 0, 0, 0.3);
            background-image: repeating-linear-gradient(135deg, transparent, transparent 5px, $color_accent_dark 3px, $color_accent_dark 7px);
            font-size: 6rem;
            font-weight: 700;
            color: $color_black;
        }
    }
    


    .product-desc {
        margin: 20px 0 0;

        .product-title {
            font-size: 1.8rem;
            line-height: 1.5;
            padding: 0 0 1rem;
            text-align: left;
            border-bottom: 1px $color_white solid;
        }
        
        .product-price {
            font-size: 2.2rem;
            line-height: 48px;
            text-align: right;
            border-bottom: 1px $color_white solid;
        }
        
        .product-buttons {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @include mobile {
                gap: 10px;
            }

            .product-button {
                display: block;
                width: 100%;
                line-height: 36px;
                text-decoration: none;
                border-radius: 6px;
                color: $color_black;
                background-color: $color_white;
                border: 1px $color_typo_gray solid;

                &:hover {
                    opacity: .8;
                }
            } 
        }
    }
    }
html {
    font-size: 62.5%;
}
body {
    font-family: $font_base;
    background: linear-gradient(100deg, rgba(25,2,8,1) 0%, rgba(62,4,28,1) 100%);
    color: $color_white;
    font-size: 1.6rem;
}

h1 {
    font-size: 3.2rem;
}

h2.heading {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    position: relative;
    padding: 6rem 0 5.4rem;

    .title {
        font-size: 5.4rem;
        font-family: $font_accent;

        @include tablet {
            font-size: 4.2rem;
        }

        @include mobile {
            font-size: 3.6rem;
        }
    }

    .subtitle {
        display: block;
        width: 100%;
        font-size: 1.6rem;
        border-bottom: 1px $color_white solid;
        padding: .3em 0 .6em;
    }

    &.left {
        padding-left: 80px;

        @include mobile {
            padding-left: 40px;
        }
        .title {
            margin-right: 3rem;

            @include mobile {
                margin-right: 1.5rem;
            }
        }
        .subtitle {
            text-align: left;
        }
    }

    &.right {
        padding-right: 80px;

        @include mobile {
            padding-right: 40px;
        }
        .title {
            margin-left: 3rem;

            @include mobile {
                margin-left: 1.5rem;
            }
        }
        .subtitle {
            text-align: right;
        }
    }

    &.center {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 5.4rem;

        @include tablet {
            font-size: 4.2rem;
        }

        @include mobile {
            font-size: 3.6rem;
        }
    }
}

p {
    font-size: 1.6rem;
    line-height: 2;
    margin-bottom: 1.6rem;
}

.font-accent {
    font-family: $font_accent;
}

img {
    width: 100%;
}

.accent-color {
    color: $color_accent;
}

.inner {
    max-width: 1080px;
    margin: 0 auto;
}

.single-page {
    padding: 120px 0 60px;
}

.single-page-header {
    padding: 40px 20px;

    .main-title-area {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        align-items: center;
        
        .subtitle {
            position: absolute;
            font-family: $font_accent;
            font-size: 4.2rem;
            top: 0;

            @include tablet {
                font-size: 3.6rem;
            }

            @include mobile {
                font-size: 3rem;
            }
        }
        
        .lines {
            position: relative;
            height: 60px;
            width: 800px;
            padding-top: 20px;
            margin: 40px 20px 0 0;
            overflow: hidden;

            .line1 {
                position: absolute;
                width: 260px;
                height: 40px;
                border-bottom: 1px $color_white solid;
                bottom: 0;

                @include tablet {
                    width: 160px;
                }

                @include mobile {
                    width: 40px;
                }
            }
            .line2 {
                position: absolute;
                height: 58px;
                border-right: 1px #fff solid;
                left: 279px;
                bottom: -10px;
                transform: rotate(45deg);

                @include tablet {
                    left: 179px;
                }

                @include mobile {
                    left: 59px;
                }
                
            }
            .line3 {
                position: absolute;
                width: 800px;
                height: 40px;
                border-bottom: 1px $color_white solid;
                top: 0;
                left: 300px;

                @include tablet {
                    left: 200px;
                }
                
                @include mobile {
                    left: 80px;
                }
            }
        }

        .page-title {
            margin-top: 40px;
        }

    }

    .page-title {
        text-align: center;
        font-weight: 700;
        font-size: 4.8rem;
        flex-shrink: 0;

        @include tablet {
            font-size: 3.8rem;
        }

        @include mobile {
            font-size: 2.8rem;
        }
    }
}

.header {
    position: fixed;
    width: 100%;
    z-index: 10;
    font-family: $font_accent;
    z-index: 1000;

    .global-nav {
        display: grid;
        padding: 0 42px 0 0;
        transition-duration: .5s;
        overflow: hidden;

        @include tablet {
            position: fixed;
            padding: 0;
            top: 20px;
            right: 20px;
            width: 48px;
            height: 48px;
            background: rgba(0,0,0,.5);
            border: 1px $color_white solid;

            &.open {
                width: 180px;
                height: 360px;
            }
        }

        &.open {
            .sp-menu-button {
                width: 60px;
                height: 60px;
            }

            ul {
                opacity: 1;
            }
        }

        .sp-menu-button {
            position: fixed;
            width: 48px;
            height: 48px;
            cursor: pointer;
            top: 20px;
            right: 20px;
            display: none;
            transition-duration: .5s;
            z-index: 1;
    
            @include tablet {
                display: block;
            }
        
            .menu-icon__cheeckbox {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                cursor: pointer;
                z-index: 2;
                -webkit-touch-callout: none;
                position: absolute;
                opacity: 0;
            }
            div {
                margin: auto;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 22px;
                height: 12px;
            }
            span {
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color_white;
                transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
        
                &:first-of-type {
                    top: 0;
                }
                &:last-of-type {
                    bottom: 0;
                }
            }
            &.active,
            .menu-icon__cheeckbox:checked + div {
                span {
                    &:first-of-type {
                        transform: rotate(45deg);
                        top: 5px;
                    }
                    &:last-of-type {
                        transform: rotate(-45deg);
                        bottom: 5px;
                    }
                }
            }
        
            &.active:hover span:first-of-type,
            &.active:hover span:last-of-type,
            &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
            &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
                width: 22px;
            }
        }

        ul {
            display: flex;
            flex-wrap: nowrap;
            list-style: none;
            align-items: center;
            transition-duration: .5s;

            @include tablet {
                display: block;
                opacity: 0;
            }

            li {
                display: block;
                margin-left: 2.4rem;
                padding: 3.6rem 0;
                flex-shrink: 0;

                @include tablet {
                    font-size: 1.6rem;
                    margin-left: 1.8rem;
                    padding: 0;
                    line-height: 60px;
                    opacity: 0;
                    transition-duration: .3s;

                    &.show {
                        opacity: 1;
                    }
                }
                
                a {
                    position: relative;
                    text-decoration: none;
                    color: $color_white;
                    transition-duration: .4s;

                    &:after {
                        position: absolute;
                        content: '';
                        width: 0px;
                        transition-duration: .4s;
                        height: 2px;
                        bottom: -5px;
                        left: 0px;
                        background: $color_accent;
                    }

                    &:hover {
                        color: $color_accent;
                        
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }

            &:before {
                content: '';
                width: 100%;
                top: 30px;
                border-bottom: 1px $color_white solid;
            }
        }
    }
}

.footer {
    padding: 60px 40px;
    background: $color_footer_bg;
    text-align: center;
    color: $color_typo_black;
    font-size: 1.4rem;

    .organizer-logo {
        max-width: 180px;
        width: 60%;
        margin: 20px auto 40px;
    }

    .social-links {
        text-align: center;
        margin-bottom: 20px;

        .icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: 0 1rem;

            &:hover {
                opacity: .5;
            }
            
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .row {
        margin-bottom: .8rem;

        span {
            display: inline-block;

            @include tablet {
                display: block;
                margin-bottom: 1.2rem;
            }

            a {
                color: $color_typo_black;
                text-decoration: underline;
                text-decoration-color: $color_typo_gray;
                text-decoration-style: dotted;
            }

            &:after {
                content: '|';
                padding: 0 1.2rem;

                @include tablet {
                    display: none;
                }
            }

            &:last-child:after {
                content: '';
            }
        }
    }
}

.glossary-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeInBackground 0.5s forwards;

    &.closing {
        animation: fadeOutBackground 0.5s forwards;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(3px);
    }

    .content {
        position: relative;
        padding: 40px;
        max-width: 720px;
        width: 90%;
        z-index: 1001;
        transform: scale(0);
        animation: zoomInContent 0.5s forwards 0.5s;
        border: 1px $color_white solid;

        @include tablet {
            padding: 20px;
        }
    
        &.closing {
            animation: zoomOutContent 0.5s forwards;
        }

        h2.label {
            position: absolute;
            font-weight: 700;
            font-size: 2.6rem;
            top: 0;
            left: 0;
            padding:  0 0 5px 4rem;
            width: 180px;
            line-height: 55px;
            height: 60px;
            box-sizing: border-box;
            color: $color_accent_dark;
            background: $color_white;

            &:after {
                position: absolute;
                content: '';
                left: 180px;
                border-right: 60px solid transparent;
                border-top: 60px solid $color_white;
            }
        }

        .glossary-header {
            display: grid;
            grid-template-columns: 2fr 1fr;
            margin-bottom: 40px;
            gap: 40px;

            @include tablet {
                grid-template-columns: 1fr;
                gap: 20px;
            }

            h3.title {
                margin-top: 80px;
                font-weight: 700;
                padding: 2rem 0;
                font-size: 2.4rem;
                border-bottom: 1px $color_white solid;

                .subtitle {
                    font-size: 1.8rem;
                    margin-top: 1rem;
                    display: block;
                }
            }

            .gloassary-image {
                max-height: 160px;
                overflow: hidden;
            }
        }

        .gloassary-desc {
            font-size: 1.5rem;
        }
    }
}

.glossary-term {
    position: relative;
    color: $color_accent_dark;
    text-decoration: none;
    cursor: pointer;

    &:after {
        position: absolute;
        content: '?';
        font-family: $font_accent;
        font-size: 10px;
        color: $color_white;
        text-align: center;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background: $color_accent_dark;
        top: -18px;
        right: -12px;

        @include mobile {
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            line-height: 11px;
            font-size: 8px;
            top: 0px;
            right: -3px;
            margin-right: 6px;
        }
    }

    &:before {
        position: absolute;
        content: '';
        border-right: 4px solid transparent;
        border-top: 6px solid $color_accent_dark;
        top: -2px;
        right: 0px;

        @include mobile {
            display: none;
        }
    }
}
  
@keyframes fadeInBackground {
    from { opacity: 0; }
    to { opacity: 1; }
}
  
@keyframes fadeOutBackground {
    from { opacity: 1; }
    to { opacity: 0; }
}
  
@keyframes zoomInContent {
    from { transform: scale(0); }
    to { transform: scale(1); }
}
  
@keyframes zoomOutContent {
    from { transform: scale(1); }
    to { transform: scale(0); }
}

.not-found {
    text-align: center;
    padding: 20px;
    font-size: 6rem;
    font-family: $font_accent;

    .glitch {
        position: absolute;
        z-index: 1;
        
        &:before, &:after {
            content: "404";
            position: absolute;
            left: 0;
            z-index: -1;
        }
        
        &:before {
            color: blue;
            clip-path: polygon(0 0%, 100% 0%, 100% 66%, 0 66%);
            animation: slide-right .15s ease-in-out infinite;
        }
        
        &:after {
            color: red;
            clip-path: polygon(0 33%, 100% 33%, 100% 100%, 0 100%);
            animation: slide-left .2s ease-in-out infinite;
        }
        }

        @keyframes slide-left {
        to { transform: translateX(2px); }
        }

        @keyframes slide-right {
        to { transform: translateX(-2px); }
        }
        
        .offset {
        transform: translateX(-2px);
        animation: offset 2s ease infinite;
        background: black;
        position: absolute;
        z-index: 2;
        color: white;
        }
        
        @keyframes offset {
        from, to {
            clip-path: polygon(0 0%, 100% 0%, 100% 15%, 0 15%);
        }
        50% {
            clip-path: polygon(0 85%, 100% 85%, 100% 100%, 0 100%);
        }
    }
}
.news-list {
    a {
        text-decoration: none;
        color: $color_white;
    }
    .news-card {
        margin: 20px 0;
        height: 150px;
        padding: 20px;
        overflow: hidden;

        @include tablet {
            height: 80px;
        }

        .news-date {
            width: 120px;
            height: 150px;
            text-align: center;
            font-family: $font_accent;
            font-size: 6rem;
            line-height: 1.2;
            float: left;

            @include tablet {
                width: 80px;
                height: 80px;
                font-size: 3.2rem;
            }

            @include mobile {
                width: 60px;
            }

            span {
                display: block;
            }
        }

        .news-thumbnail {
            position: relative;
            width: 240px;
            height: 150px;
            margin-left: 10px;
            overflow: hidden;
            float: left;

            @include tablet {
                width: 150px;
                height: 80px;
            }

            @include mobile {
                width: 140px;
            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                -webkit-transform: translateY(-50%) translateX(-50%);
                margin: 0;
            }
        }

        .news-meta {
            margin-left: 396px;

            @include tablet {
                margin-left: 266px;
            }

            @include mobile {
                margin-left: 230px;
            }
            
            .news-title {
                font-size: 2.3rem;
                font-weight: 700;
                margin-bottom: .8rem;
                line-height: 1.5;

                @include tablet {
                    font-size: 2.1rem;
                }

                @include mobile {
                    font-size: 1.6rem;
                }
            }

            .news-excerpt {
                font-size: 1.5rem;
                line-height: 1.5;

                @include mobile {
                    display: none;
                }
            }
        }
    }
}

.news-detail {
    min-height: 100vh;
    padding: 20px 20px 80px;

    header {
        margin-top: 120px;

        h1 {
            font-size: 2.8rem;
            line-height: 1.5;
            font-weight: 700;
        }
    }

    .news-thumbnail {
        margin: 0 0 20px;
    }
    
    .news-content {
        line-height: 1.5;

        h2 {
            font-size: 2.2rem;
            padding: 1.6rem 0 1rem;
            margin: 2rem 0 0;
            font-weight: 700;
        }

        h3 {
            font-size: 1.8rem;
            padding: 1.6rem 0 1rem;
            margin: 2rem 0 0;
            font-weight: 700;
        }

        ul {
            list-style: disc;
            margin: 0 0 0 24px;

        }

        table {
            width: 100%;
            
            tbody {
                tr {
                    border-bottom: 1px $color_typo_gray solid;

                    td {
                        vertical-align: middle;
                        padding: .8rem;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}
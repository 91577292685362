@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Zen+Old+Mincho&display=swap');
$font_base: "Zen Old Mincho", serif;
$font_accent: "Gloock", serif;

$color_white: #fff;
$color_black: #000;
$color_bg: $color_black;
$color_accent: #ff0000;
$color_accent_dark: #a11010;
$color_typo_gray: #666;
$color_typo_black: #333;
$color_footer_bg: #eee;

$mb: 639px;
$tb: 959px;
@mixin mobile {
    @media screen and (max-width: $mb) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: $tb) {
        @content;
    }
}

@mixin uwide {
    @media screen and (min-width: 1480px) and (max-height: 1200px) {
        @content;
    }
}
.fc-only-subtitle {
    position: relative;
    margin: 20px 0;
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;    
    display: flex;
    align-items: center;

    @include tablet {
        font-size: 2.4rem;
    }

    @include mobile {
        font-size: 2.2rem;
    }

    &:before,
    &:after {
        content: "";
        height: 20px;
        flex-grow: 1;
        background-size: auto auto;
        background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, $color_white 10px, $color_white 20px);
    }

    &:before {
        margin-right: 2rem;
    }

    &:after {
        margin-left: 2rem;
    }
}
.fc-content {
    text-align: center;
    padding: 20px;

    .overview,
    .aikotoba-desc {
        font-size: 2rem;
    }

    .goto-membership-button {
        display: inline-block;
        border-radius: 8px;
        border: 1px $color_white solid;
        line-height: 40px;
        padding: 1rem 2.4rem;
        color: $color_white;
        text-decoration: none;
        font-weight: 700;
        margin-bottom: 40px;

        @include mobile {
            width: 100%;
            padding: 12px 0;
        }

        span {
            padding: 0 .2rem;
            @include tablet {
                display: block;
                line-height: 30px;
                font-size: 1.4rem;
            }
        }
    }

    .aikotoba-input {
        width: 200px;
        line-height: 48px;
        font-size: 2.4rem;
        text-align: center;
        border: 1px $color_white solid;
        line-height: 40px;
        padding: 1rem 2.4rem;
        color: $color_white;
        border-radius: 8px;
        background: none;
    }
}